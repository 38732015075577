import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import WarningDialog from '@/components/core/WarningDialog.vue';
import RichTextEditor from '@/components/core/RichTextEditor.vue';
import axios from 'axios';
import { TaskClass } from '@/models/task/Task.js';
import LBAFilterExpansion from '@/components/ui/expansion/LBAFilterExpansion.vue';
import { DateTime } from 'luxon';

// https://techformist.com/vuetify-input-field-date-picker/
// filters: https://front.id/en/articles/vuetify-achieve-multiple-filtering-data-table-component

export default {
  components: {
    WarningDialog,
    RichTextEditor,
    LBAFilterExpansion
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    wf_cid: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: true,
      dialog: false,
      dialogDelete: false,
      search: '',
      expanded: [],
      singleExpand: false,
      sortDesc: true,
      editorContent: '',
      dialogInputMethods: ['Manuell', 'E-Mail', 'Telefon'],
      headers: [{
        text: 'Erstellungsdatum',
        align: 'start',
        value: 'aDatF'
      }, {
        text: 'Zuletzt bearbeitet',
        align: 'start',
        value: 'mDatF'
      }, {
        text: 'Betreff',
        value: 'subject'
      }, {
        text: 'Erfassung',
        value: 'capture'
      }, {
        text: 'Stichworte',
        value: 'keywords'
      }, {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12em'
      }],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        mDatF: '',
        subject: '',
        capture: '',
        keywords: '',
        text: '',
        documentName: '',
        wf_cid: ''
      },
      datePickerMenu1: false,
      datePickerMenu2: false,
      //textFieldInput: '',
      inputRequirements: [inputValue => !!inputValue || 'Feld ausfüllen', inputValue => inputValue && inputValue.length >= 2 || 'Min. 2 Buchstaben'],
      inputRequirements2: [dateInputValue => !!dateInputValue || 'Feld ausfüllen'],
      betreffSelectorItems: ['item1', 'item2', 'item3', 'item4'],
      erfassungSelectorItems: ['item1', 'item2', 'item3'],
      date: null,
      date1: '',
      date2: '',
      selectedSubject: null,
      warningDialog: false,
      actions: [],
      selectedCapture: null,
      allSubject: [],
      //alle Betreffe (:items)
      allCapture: [],
      //alle Erfassungen (:items)
      taskSnackbar: false,
      createdTaskId: 0
    };
  },
  computed: {
    ...mapState({
      interactions: state => state.crmInteractions.interactions,
      availableSubjects: state => state.crmInteractions.availableSubjects
    }),
    accessToTask() {
      return this.$store.getters.userHasApp('Task');
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Neue Interaktion' : 'Interaktion bearbeiten';
    },
    isNewInteraction() {
      if (this.editedIndex == -1) {
        return true;
      }
      return false;
    },
    isDocumentInteractions() {
      return this.wf_cid != null && this.wf_cid != '';
    },
    formattedDate1() {
      return this.date1 ? DateTime.fromISO(this.date1).toLocaleString() : '';
    },
    formattedDate2() {
      return this.date2 ? DateTime.fromISO(this.date2).toLocaleString() : '';
    },
    interactionsFiltered() {
      let filteredInteractions = this.interactions.filter(item => {
        var _item$subject;
        let isStartDateValid = this.date1 == null || this.date1 === '' || item.aDat > this.date1;
        let isEndDateValid = this.date2 == null || this.date2 === '' || item.aDat < this.date2;
        let isSubjectValid = this.selectedSubject == null || ((_item$subject = item.subject) === null || _item$subject === void 0 ? void 0 : _item$subject.code) === this.selectedSubject.code;
        let isCaptureValid = this.selectedCapture == null || item.capture === this.selectedCapture;
        return isStartDateValid && isEndDateValid && isSubjectValid && isCaptureValid;
      });
      let beginDateFormat = new Date(this.date1);
      let endDateFormat = new Date(this.date2);
      if (endDateFormat < beginDateFormat) {
        this.$toast.warning('Das Enddatum ist vor dem Beginndatum');
      }
      return filteredInteractions;
    }
  },
  methods: {
    init() {
      this.$store.dispatch('loadAvailableSubjects', {
        wf_cid: this.wf_cid
      });
      this.loadInteractions();
      this.fillFilter();
    },
    loadInteractions() {
      this.$store.dispatch('loadInteractions', {
        lfdnr: this.lfdnr,
        wf_cid: this.wf_cid
      }).then(this.loading = false);
    },
    closeWarningDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    open(warnMessage, actions, additionalData = []) {
      this.warnMessage = warnMessage;
      this.actions = actions;
      this.warningDialog = true;
      this.colsPerButton = Math.floor(12 / actions.length);
      this.additionalData = additionalData;
    },
    warningActionPerformed(action) {
      this.$emit('warningActionPerformed', action);
      this.warningDialog = false;
    },
    newInteraction() {
      this.$store.dispatch('loadAvailableSubjects', {
        wf_cid: this.wf_cid
      });
    },
    editInteraction(item) {
      this.$store.dispatch('loadAvailableSubjects', {
        wf_cid: this.wf_cid
      });
      this.editedIndex = this.interactions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteInteraction(item) {
      this.editedIndex = this.interactions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch('deleteInteraction', {
        lfdnr: this.lfdnr,
        wf_cid: this.wf_cid,
        interaction: this.editedItem
      });
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.editedItem.text = this.$refs.richTextEditor.editor.getHTML();
      if (this.editedIndex > -1) {
        this.$store.dispatch('editInteraction', {
          lfdnr: this.lfdnr,
          wf_cid: this.wf_cid,
          interaction: this.editedItem
        });
      } else {
        this.editedItem.wf_cid = this.wf_cid;
        this.$store.dispatch('addInteraction', {
          lfdnr: this.lfdnr,
          wf_cid: this.wf_cid,
          interaction: this.editedItem
        }).then(() => {
          this.editedItem = this.defaultItem;
          this.editedIndex = -1;
          this.loadInteractions();
        });
      }
    },
    saveWithoutTask() {
      this.save();
      this.close();
    },
    saveWithTask() {
      this.save();
      const newTask = new TaskClass();
      newTask.description = this.$refs.richTextEditor.editor.getHTML();
      newTask.dueDate = null;
      newTask.title = this.editedItem.subject.description + ' ' + this.editedItem.keywords;
      var documentObjects = [];
      var addressObjects = [];
      if (this.isDocumentInteractions) {
        var object = {
          wfCid: this.wf_cid
        };
        console.log(object);
        documentObjects.push(object);
      }
      axios.post('v1/task-resources/base/addresses', [this.lfdnr]).then(response => {
        addressObjects = JSON.parse(JSON.stringify(response.data));
        addressObjects = response.data.map(object => {
          return {
            wfCid: object.wfCid
          };
        });
        newTask.objects = documentObjects.concat(addressObjects);
        axios.put('/v1/task', newTask).then(response => {
          this.createdTaskId = response.data.lfdnr;
          this.openCreatedTask();
        });
      });
      this.close();
    },
    openCreatedTask() {
      this.$router.push({
        name: 'TaskDetails',
        params: {
          lfdnr: this.createdTaskId
        }
      });
    },
    resetFilter() {
      this.date1 = null;
      this.date2 = null;
      this.selectedSubject = null;
      this.selectedCapture = null;
    },
    fillFilter() {
      if (this.date1 == null || '') {
        this.date1 = '';
      }
      if (this.date2 == null || '') {
        this.date2 = '';
      }
      this.availableSubjects.forEach(elem => {
        this.allSubject.push(elem.selectedSubject);
        this.allCapture.push(elem.selectedCapture);
      });
    }
    //getExternalData() {}
  },
  watch: {
    lfdnr() {
      this.init();
    },
    wf_cid() {
      this.init();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  mounted() {
    this.init();
  }
};