import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, [_c(VCardTitle, [_c(VTextField, {
    staticStyle: {
      "margin-bottom": "35px"
    },
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VCardText, [_c(VRow, {
    staticClass: "mt-n7 mb-4 d-flex align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VExpansionPanels, {
    attrs: {
      "accordion": ""
    }
  }, [_c('LBAFilterExpansion', {
    attrs: {
      "text-color": 'default'
    },
    on: {
      "clear": function ($event) {
        return _vm.resetFilter();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "6",
            "lg": "3",
            "xl": "3"
          }
        }, [_c(VMenu, {
          ref: "datePickerMenu1",
          staticClass: "mx-sm-1",
          attrs: {
            "close-on-content-click": false,
            "max-width": "400px",
            "min-width": "auto",
            "offset-y": "",
            "transition": "scale-transition",
            "origin": "center center"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on,
              attrs
            }) {
              return [_c(VTextField, _vm._g(_vm._b({
                staticClass: "mx-sm-1",
                attrs: {
                  "value": _vm.formattedDate1,
                  "clearable": "",
                  "label": "Startdatum",
                  "required": "",
                  "outlined": "",
                  "append-icon": "mdi-calendar",
                  "flat": "",
                  "solo": ""
                },
                on: {
                  "click:clear": function ($event) {
                    _vm.date1 = null;
                  }
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }]),
          model: {
            value: _vm.datePickerMenu1,
            callback: function ($$v) {
              _vm.datePickerMenu1 = $$v;
            },
            expression: "datePickerMenu1"
          }
        }, [_c(VDatePicker, {
          attrs: {
            "color": "primary"
          },
          model: {
            value: _vm.date1,
            callback: function ($$v) {
              _vm.date1 = $$v;
            },
            expression: "date1"
          }
        }, [_c(VSpacer), _c('LBAButton', {
          attrs: {
            "buttonType": 'error',
            "buttonStyle": 'text'
          },
          on: {
            "click": function ($event) {
              _vm.datePickerMenu1 = false;
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v("Abbrechen")];
            },
            proxy: true
          }])
        }), _c('LBAButton', {
          attrs: {
            "buttonType": 'primary',
            "buttonStyle": 'text'
          },
          on: {
            "click": function ($event) {
              return _vm.$refs.datePickerMenu1.save(_vm.date1);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v("Ok")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "6",
            "lg": "3",
            "xl": "3"
          }
        }, [_c(VMenu, {
          ref: "datePickerMenu2",
          attrs: {
            "close-on-content-click": false,
            "max-width": "290",
            "min-width": "auto",
            "offset-y": "",
            "transition": "scale-transition",
            "origin": "center center"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on,
              attrs
            }) {
              return [_c(VTextField, _vm._g(_vm._b({
                staticClass: "mx-sm-1",
                attrs: {
                  "value": _vm.formattedDate2,
                  "clearable": "",
                  "label": "Enddatum",
                  "required": "",
                  "outlined": "",
                  "append-icon": "mdi-calendar",
                  "solo": "",
                  "flat": ""
                },
                on: {
                  "click:clear": function ($event) {
                    _vm.date2 = null;
                  }
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }]),
          model: {
            value: _vm.datePickerMenu2,
            callback: function ($$v) {
              _vm.datePickerMenu2 = $$v;
            },
            expression: "datePickerMenu2"
          }
        }, [_c(VDatePicker, {
          attrs: {
            "color": "primary"
          },
          model: {
            value: _vm.date2,
            callback: function ($$v) {
              _vm.date2 = $$v;
            },
            expression: "date2"
          }
        }, [_c(VSpacer), _c('LBAButton', {
          attrs: {
            "buttonType": 'error',
            "buttonStyle": 'text'
          },
          on: {
            "click": function ($event) {
              _vm.datePickerMenu2 = false;
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v("Abbrechen")];
            },
            proxy: true
          }])
        }), _c('LBAButton', {
          attrs: {
            "buttonType": 'primary',
            "buttonStyle": 'text'
          },
          on: {
            "click": function ($event) {
              return _vm.$refs.datePickerMenu2.save(_vm.date2);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v("Ok")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "6",
            "lg": "3",
            "xl": "3"
          }
        }, [_c(VAutocomplete, {
          staticClass: "mx-sm-1",
          attrs: {
            "label": "Betreff",
            "required": "",
            "items": _vm.availableSubjects,
            "outlined": "",
            "transition": "scale-transition",
            "origin": "center center",
            "item-text": "description",
            "item-value": item => item,
            "return-object": "",
            "clearable": "",
            "small-chips": "",
            "flat": "",
            "solo": ""
          },
          model: {
            value: _vm.selectedSubject,
            callback: function ($$v) {
              _vm.selectedSubject = $$v;
            },
            expression: "selectedSubject"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "6",
            "lg": "3",
            "xl": "3"
          }
        }, [_c(VAutocomplete, {
          staticClass: "mx-sm-1",
          attrs: {
            "label": "Erfassung",
            "required": "",
            "outlined": "",
            "items": _vm.dialogInputMethods,
            "transition": "scale-transition",
            "origin": "center center",
            "return-object": "",
            "clearable": "",
            "small-chips": "",
            "flat": "",
            "solo": ""
          },
          model: {
            value: _vm.selectedCapture,
            callback: function ($$v) {
              _vm.selectedCapture = $$v;
            },
            expression: "selectedCapture"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c(VDataTable, {
    staticClass: "elevation-0",
    staticStyle: {
      "margin-top": "-30px"
    },
    attrs: {
      "headers": _vm.headers,
      "items": _vm.interactionsFiltered,
      "items-per-page": 10,
      "search": _vm.search,
      "item-key": "aDat",
      "expanded": _vm.expanded,
      "show-expand": "",
      "single-expand": _vm.singleExpand,
      "loading": _vm.loading,
      "sort-by": "aDat",
      "sort-desc": _vm.sortDesc,
      "mobile-breakpoint": "800"
    },
    on: {
      "update:expanded": function ($event) {
        _vm.expanded = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VToolbar, {
          attrs: {
            "flat": ""
          }
        }, [_c(VSpacer), _c('LBADialog', {
          attrs: {
            "persistent": true,
            "maxWidth": 900
          },
          on: {
            "close": function ($event) {
              return _vm.close();
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on,
              attrs
            }) {
              return [_c('LBAButton', _vm._g(_vm._b({
                attrs: {
                  "buttonType": 'primary'
                },
                on: {
                  "click": function ($event) {
                    _vm.newInteraction(), _vm.dialog = true;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "content",
                  fn: function () {
                    return [_vm._v("Neue Interaktion")];
                  },
                  proxy: true
                }], null, true)
              }, 'LBAButton', attrs, false), on))];
            }
          }, {
            key: "title",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.formTitle) + " ")];
            },
            proxy: true
          }, {
            key: "content",
            fn: function () {
              return [_c(VRow, {
                staticClass: "pt-4"
              }, [_c(VCol, {
                staticClass: "pa-0 pr-1",
                attrs: {
                  "cols": "12",
                  "sm": "6",
                  "md": "4"
                }
              }, [!_vm.isNewInteraction ? _c(VTextField, {
                attrs: {
                  "label": "Erstellungsdatum",
                  "outlined": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.editedItem.aDatF,
                  callback: function ($$v) {
                    _vm.$set(_vm.editedItem, "aDatF", $$v);
                  },
                  expression: "editedItem.aDatF"
                }
              }) : _vm._e()], 1), _c(VCol, {
                staticClass: "pa-0 pl-md-1",
                attrs: {
                  "cols": "12",
                  "sm": "6",
                  "md": "4"
                }
              }, [!_vm.isNewInteraction ? _c(VTextField, {
                attrs: {
                  "label": "Zuletzt bearbeitet",
                  "outlined": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.editedItem.mDatF,
                  callback: function ($$v) {
                    _vm.$set(_vm.editedItem, "mDatF", $$v);
                  },
                  expression: "editedItem.mDatF"
                }
              }) : _vm._e()], 1), _c(VCol, {
                staticClass: "pa-0 pl-md-2",
                attrs: {
                  "cols": "12",
                  "md": "4"
                }
              }, [!_vm.isNewInteraction ? _c(VTextField, {
                attrs: {
                  "label": "Dokument",
                  "outlined": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.editedItem.documentName,
                  callback: function ($$v) {
                    _vm.$set(_vm.editedItem, "documentName", $$v);
                  },
                  expression: "editedItem.documentName"
                }
              }) : _vm._e()], 1), _c(VCol, {
                staticClass: "pa-0 pr-1",
                attrs: {
                  "cols": "12",
                  "sm": "6",
                  "md": "6"
                }
              }, [_c(VSelect, {
                attrs: {
                  "items": _vm.dialogInputMethods,
                  "label": "Erfassung",
                  "outlined": "",
                  "clearable": ""
                },
                model: {
                  value: _vm.editedItem.capture,
                  callback: function ($$v) {
                    _vm.$set(_vm.editedItem, "capture", $$v);
                  },
                  expression: "editedItem.capture"
                }
              })], 1), _c(VCol, {
                staticClass: "pa-0 pl-md-1",
                attrs: {
                  "cols": "12",
                  "sm": "6",
                  "md": "6"
                }
              }, [_c(VSelect, {
                attrs: {
                  "label": "Betreff",
                  "clearable": "",
                  "outlined": "",
                  "items": _vm.availableSubjects,
                  "item-text": "description",
                  "return-object": ""
                },
                model: {
                  value: _vm.editedItem.subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.editedItem, "subject", $$v);
                  },
                  expression: "editedItem.subject"
                }
              })], 1), _c(VCol, {
                staticClass: "pa-0",
                attrs: {
                  "cols": "12"
                }
              }, [_c(VTextField, {
                attrs: {
                  "label": "Stichworte",
                  "outlined": ""
                },
                model: {
                  value: _vm.editedItem.keywords,
                  callback: function ($$v) {
                    _vm.$set(_vm.editedItem, "keywords", $$v);
                  },
                  expression: "editedItem.keywords"
                }
              })], 1), _c(VCol, {
                staticClass: "pa-0",
                attrs: {
                  "cols": "12"
                }
              }, [_c('RichTextEditor', {
                ref: "richTextEditor",
                attrs: {
                  "text": _vm.editedItem.text,
                  "editable": ""
                }
              })], 1)], 1)];
            },
            proxy: true
          }, {
            key: "success",
            fn: function () {
              return [_vm.accessToTask ? _c(VMenu, {
                attrs: {
                  "offset-y": "",
                  "top": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function ({
                    on,
                    attrs
                  }) {
                    return [_c('LBAButton', _vm._g(_vm._b({
                      attrs: {
                        "buttonType": 'success'
                      },
                      scopedSlots: _vm._u([{
                        key: "content",
                        fn: function () {
                          return [_vm._v(" Speichern ")];
                        },
                        proxy: true
                      }], null, true)
                    }, 'LBAButton', attrs, false), on))];
                  }
                }], null, false, 2362431989)
              }, [_c(VList, [_c(VListItem, {
                on: {
                  "click": _vm.saveWithoutTask
                }
              }, [_c(VListItemTitle, [_vm._v("Speichern")])], 1), _c(VListItem, {
                on: {
                  "click": _vm.saveWithTask
                }
              }, [_c(VListItemTitle, [_vm._v("Speichern und Task erstellen")])], 1)], 1)], 1) : _c('LBAButton', {
                attrs: {
                  "buttonType": 'success'
                },
                on: {
                  "click": _vm.saveWithoutTask
                },
                scopedSlots: _vm._u([{
                  key: "content",
                  fn: function () {
                    return [_vm._v("Speichern")];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v;
            },
            expression: "dialog"
          }
        }), _c('WarningDialog', {
          ref: "warning",
          on: {
            "warningActionPerformed": _vm.warningActionPerformed
          }
        }), _c('LBADialog', {
          attrs: {
            "dialogType": 'warning',
            "width": 650
          },
          on: {
            "close": function ($event) {
              return _vm.closeWarningDialog();
            }
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("Warnung")];
            },
            proxy: true
          }, {
            key: "content",
            fn: function () {
              return [_c(VRow, {
                staticClass: "mx-2"
              }, [_c(VCol, {
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" Wollen Sie die Interaktion "), _c('strong', [_vm._v("\"" + _vm._s(_vm.editedItem.keywords) + "\"")]), _vm._v(" wirklich löschen? ")])], 1)];
            },
            proxy: true
          }, {
            key: "actions",
            fn: function () {
              return [_c('LBAButton', {
                attrs: {
                  "buttonType": 'error'
                },
                on: {
                  "click": function ($event) {
                    return _vm.deleteItemConfirm();
                  }
                },
                scopedSlots: _vm._u([{
                  key: "content",
                  fn: function () {
                    return [_vm._v("Löschen")];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v;
            },
            expression: "dialogDelete"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "item.documentName",
      fn: function ({
        item
      }) {
        return [item.wf_cid ? _c('router-link', {
          attrs: {
            "to": {
              name: 'crmAddressDocumentDetailed',
              params: {
                lfdnr: _vm.lfdnr,
                documentId: item.wf_cid
              }
            }
          }
        }, [_c('span', [_vm._v(_vm._s(item.documentName))])]) : _vm._e()];
      }
    }, {
      key: "item.subject",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item == null || item.subject == null || item.subject.description == null ? '-' : item.subject.description) + " ")];
      }
    }, {
      key: "expanded-item",
      fn: function ({
        headers,
        item
      }) {
        return [item.text || item.wf_cid ? _c('td', {
          staticClass: "px-4 py-2 white",
          attrs: {
            "colspan": headers.length
          }
        }, [item.text ? _c('div', {
          staticClass: "pa-3"
        }, [_c('h3', {
          staticClass: "mb-2"
        }, [_vm._v("Text")]), _c('p', {
          domProps: {
            "innerHTML": _vm._s(item.text)
          }
        })]) : _vm._e(), item.wf_cid ? _c('div', [_c('h3', [_vm._v("Dokument:")]), _c('router-link', {
          attrs: {
            "to": {
              name: 'crmAddressDocumentDetailed',
              params: {
                lfdnr: _vm.lfdnr,
                wf_cid: item.wf_cid
              }
            }
          }
        }, [_c('span', [_vm._v(_vm._s(item.documentName))])])], 1) : _vm._e()]) : _c('td', {
          staticClass: "white px-4 py-2",
          attrs: {
            "colspan": headers.length
          }
        }, [_vm._v(" Keine Daten vorhanden. ")])];
      }
    }, {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c('LBAButton', {
          attrs: {
            "buttonStyle": 'icon'
          },
          on: {
            "click": function ($event) {
              return _vm.editInteraction(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-pencil")])];
            },
            proxy: true
          }], null, true)
        }), _c('LBAButton', {
          attrs: {
            "buttonStyle": 'icon',
            "buttonType": 'error'
          },
          on: {
            "click": function ($event) {
              return _vm.deleteInteraction(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-delete")])];
            },
            proxy: true
          }], null, true)
        })];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Interaktionen vorhanden ")];
      },
      proxy: true
    }])
  }), _c(VSnackbar, {
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        attrs
      }) {
        return [_c(VBtn, _vm._b({
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.openCreatedTask
          }
        }, 'v-btn', attrs, false), [_vm._v(" Öffnen ")])];
      }
    }]),
    model: {
      value: _vm.taskSnackbar,
      callback: function ($$v) {
        _vm.taskSnackbar = $$v;
      },
      expression: "taskSnackbar"
    }
  }, [_vm._v(" Task wurde erstellt ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };