export default {
  props: {
    textColor: {
      type: String,
      default: 'default'
    },
    titleType: {
      type: String,
      default: 'default'
    },
    sideBar: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clicked() {
      this.$emit('click');
    }
  }
};